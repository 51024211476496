import './Graph.sass'
import React, { createRef, FC, RefObject } from 'react'
import { ReactComponent as GraphSvg } from './Graph.svg'
import { ReactComponent as GraphMobileSvg } from './GraphMobile.svg'
import useInViewport from '../../misc/useInViewport'
import isMobile from '../../misc/isMobile'

const Graph: FC = () => {
    const svgRef: RefObject<SVGSVGElement> = createRef()
    const inViewport = useInViewport(svgRef)

    return (
        <div className={`graph ${inViewport ? 'inViewport' : ''}`}>
            {Array.apply(null, Array(3)).map((_, idx) => (
                <div key={idx} className='graph__point' />
            ))}
            {isMobile() ? (
                <GraphMobileSvg ref={svgRef} className='graph__svg mopbile' />
            ) : (
                <GraphSvg ref={svgRef} className='graph__svg' />
            )}
        </div>
    )
}

export default Graph
