import './Customers.sass'
import React, { FC } from 'react'
import useI18n from '../../misc/i18n/useI18n'
import { Texts } from '../../misc/i18n/texts'
import Button from '../Button'
import scrollToForm from '../../misc/scrollToForm'

const CUSTOMER_LIST: Array<{ key: string, text: keyof Texts}> = [
    { key: 'AIRLINE', text: 'CUSTOMERS_AIRLINE' },
    { key: 'AIRPORT', text: 'CUSTOMERS_AIRPORT' },
    { key: 'OTA', text: 'CUSTOMERS_OTA' },
]

const Customers: FC = () => {
    const texts = useI18n()

    return (
        <div className='customers'>
            <h2 className='customers__title'>{texts.CUSTOMERS_TITLE}</h2>
            <p className='customers__text' dangerouslySetInnerHTML={{ __html: texts.CUSTOMERS_TEXT }} />
            <div className='customers__items'>
                {CUSTOMER_LIST.map(({ key, text }) => (
                    <div key={key} className={`customers__item customers__item_${key.toLowerCase()}`}>
                        <div className='customers__pic' />
                        <div className='customers__subtitle'>{texts[text]}</div>
                    </div>
                ))}
            </div>
            <Button
                color='accent'
                variant='contained'
                onClick={scrollToForm}
                className='customers__action'
            >{texts.TRY_OUT}</Button>
        </div>
    )
}

export default Customers
