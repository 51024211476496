import './RequestDemo.sass'
import React, { ChangeEvent, FC, FormEvent, useState } from 'react'
import Button from '../Button'
import useI18n from '../../misc/i18n/useI18n'
import axios from 'axios'

const RequestDemo: FC = () => {
    const texts = useI18n()
    const [ email, setEmail ] = useState('')
    const [ isBusy, setIsBusy ] = useState(false)
    const [ success, setSuccess ] = useState(false)

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setEmail(e.target.value.trim().toLowerCase())
        setSuccess(false)
    }

    const onSubmit = (e: FormEvent): void => {
        e.preventDefault()
        if (isBusy || success) return
        setIsBusy(true)
        axios.put('/api/email', { email }).then(() => {
            setIsBusy(false)
            setSuccess(true)
        })
    }

    return (
        <div className='demo' id='demo'>
            <div className='demo__content'>
                <h2 className='demo__title'>{texts.DEMO_TITLE}</h2>
                <p className='demo__text'>{texts.DEMO_TEXT}</p>
                <form className={`demo__form ${success ? 'success' : ''}`} onSubmit={onSubmit}>
                    <input
                        required
                        type='email'
                        value={email}
                        name='email'
                        onChange={onChange}
                        autoComplete='email'
                        className='demo__input'
                        placeholder={texts.DEMO_PLACEHOLDER}
                    />
                    {success ? (
                        <Button
                            type='submit'
                            color='accent'
                            variant='contained'
                            className='demo__action'
                        >{texts.SUCCESS}</Button>
                    ) : (
                        <Button
                            type='submit'
                            color='accent'
                            variant='contained'
                            className='demo__action'
                        >{texts.TRY_OUT}</Button>
                    )}
                </form>
            </div>
        </div>
    )
}

export default RequestDemo
