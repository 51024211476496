import './Promo.sass'
import React, { createRef, FC, RefObject } from 'react'
import useI18n from '../../misc/i18n/useI18n'
import useInViewport from '../../misc/useInViewport'

const Promo: FC = () => {
    const texts = useI18n()
    const containerRef: RefObject<HTMLDivElement> = createRef()
    const inViewport = useInViewport(containerRef)

    return (
        <div className={`promo ${inViewport ? 'inViewport' : ''}`}>
            <div ref={containerRef} className='promo__inner'>
                <div className='promo__title'>{texts.PROMO_TITLE}</div>
                <div className='promo__text' dangerouslySetInnerHTML={{ __html: texts.PROMO_TEXT }} />
            </div>
        </div>
    )
}

export default Promo
