import './Hero.sass'
import React, { FC } from 'react'
import useI18n from '../../misc/i18n/useI18n'
import Button from '../Button'
import scrollToForm from '../../misc/scrollToForm'

const Hero: FC = () => {
    const texts = useI18n()

    return (
        <div className='hero'>
            <div className='hero__inner'>
                <div className='hero__content'>
                    <h1 className='hero__title'>{texts.HERO_TITLE}</h1>
                    <p className='hero__text' dangerouslySetInnerHTML={{ __html: texts.HERO_TEXT }} />
                    <Button
                        color='accent'
                        variant='contained'
                        onClick={scrollToForm}
                        className='hero__button'
                    >{texts.TRY_OUT}</Button>
                </div>
                <div className='hero__decor' />
            </div>
        </div>
    )
}

export default Hero
