import './Benefits.sass'
import React, { FC } from 'react'
import useI18n from '../../misc/i18n/useI18n'
import { Texts } from '../../misc/i18n/texts'

const BENEFITS: Array<{ key: string, text: keyof Texts}> = [
    { key: 'PULSE', text: 'BENEFITS_PULSE' },
    { key: 'TRENDS', text: 'BENEFITS_TRENDS' },
    { key: 'MONITORING', text: 'BENEFITS_MONITORING' },
]

const Benefits: FC = () => {
    const texts = useI18n()

    return (
        <div className='benefits'>
            <h2 className='benefits__title'>{texts.BENEFITS_TITLE}</h2>
            <p className='benefits__text' dangerouslySetInnerHTML={{ __html: texts.BENEFITS_TEXT }} />
            <div className='benefits__list'>
                {BENEFITS.map(({ key, text }) => (
                    <div key={key} className={`benefits__item benefits__item_${key.toLowerCase()}`}>
                        <div className='benefits__caption'>{texts[text]}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Benefits
