import EventEmitter from 'eventemitter3'
import Lang from './Lang'
import TextsMap, { Texts } from './texts'

const LANG_KEY = 'AVIASTATS_LANG'
const CHANGE_LANG_EVENT = 'CHANGE_LANG_EVENT'

const getDefaultLang = (): Lang => {
    if (window.location.pathname === '/en') return Lang.EN
    let lang: Lang | undefined = <Lang | undefined>localStorage.getItem(LANG_KEY)
    if (lang && Lang[lang]) return lang
    if (navigator.language.toLowerCase().indexOf('ru') !== -1) return Lang.RU
    return Lang.EN
}

const I18nService = new class extends EventEmitter {
    private selectedLang: Lang = getDefaultLang()
    private _texts: Texts = TextsMap[this.selectedLang]

    get texts(): Texts {
        return this._texts
    }

    constructor() {
        super();
        document.title = this._texts.TITLE
        document.documentElement.lang = this._texts.CODE.toLowerCase()
    }

    public changeLang(lang: Lang): void {
        if (!Object.prototype.hasOwnProperty.call(Lang, lang)) return
        if (this.selectedLang === lang) return
        this.selectedLang = lang
        this._texts = TextsMap[this.selectedLang]
        document.title = this._texts.TITLE
        document.documentElement.lang = this._texts.CODE.toLowerCase()
        this.emit(CHANGE_LANG_EVENT, this._texts)
    }

    public onLangChanged(callback: (texts: Texts) => void): Unsubscribe {
        this.on(CHANGE_LANG_EVENT, callback)

        return () => this.off(CHANGE_LANG_EVENT, callback)
    }
}()

export default I18nService

type Unsubscribe = () => void
