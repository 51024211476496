import './Button.sass'
import React, { FC, PropsWithChildren } from 'react'
import PropTypes from 'prop-types'

type IButtonProps = PropsWithChildren<{
    href?: string
    className?: string
    component?: 'button' | 'a'
    type?: 'button' | 'submit'
    color?: 'primary' | 'accent'
    variant?: 'outlined' | 'contained'
    onClick?: (e: React.MouseEvent) => void
    disabled?: boolean
}>
const Button: FC<IButtonProps> = ({
    component,
    className,
    variant,
    onClick,
    color,
    type = 'button',
    disabled = false,
    href,
    children,
}) => {
    const TagName = component || 'button'

    if (TagName === 'button')
        return (
            <TagName
                type={type}
                onClick={onClick}
                disabled={disabled}
                className={`button ${variant || 'contained'} ${color || 'primary'} ${className || ''}`}
            >
                {children}
            </TagName>
        )
    else
        return (
            <TagName
                href={href}
                onClick={onClick}
                className={`button ${variant || 'contained'} ${color || 'primary'} ${className || ''}`}
            >
                {children}
            </TagName>
        )
}

Button.propTypes = {
    component: PropTypes.oneOf(['button', 'a']),
    className: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'contained']),
    color: PropTypes.oneOf(['primary', 'accent']),
    type: PropTypes.oneOf(['button', 'submit']),
    href: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default Button
