import './Offer.sass'
import React, { FC } from 'react'
import useI18n from '../../misc/i18n/useI18n'

const Offer: FC = () => {
    const texts = useI18n()

    return (
        <div className='offer'>
            <h2 className='offer__title'>{texts.OFFER_TITLE}</h2>
            <p className='offer__text' dangerouslySetInnerHTML={{ __html: texts.OFFER_TEXT }} />
            <div className='offer__cards'>
                <div className='offer__card offer__card_report'>
                    <div className='offer__icon' />
                    <div className='offer__subtitle'>{texts.OFFER_REPORTS_TITLE}</div>
                    <div className='offer__body' dangerouslySetInnerHTML={{ __html: texts.OFFER_REPORTS_TEXT }} />
                </div>
                <div className='offer__card offer__card_data'>
                    <div className='offer__icon' />
                    <div className='offer__subtitle'>{texts.OFFER_DATA_TITLE}</div>
                    <div className='offer__body' dangerouslySetInnerHTML={{ __html: texts.OFFER_DATA_TEXT }} />
                </div>
            </div>
        </div>
    )
}

export default Offer
