import { RefObject, useEffect, useState } from 'react'
import getFactor from './getPositionFactor'

const useInViewport = (ref: RefObject<HTMLDivElement | SVGSVGElement>) => {
    const [ inViewport, setInViewport ] = useState(false)

    useEffect(() => {
        if (inViewport) return

        const papersView: HTMLDivElement | SVGSVGElement | null = ref.current

        if (!papersView) return

        const onScroll = () => {
            const factor = getFactor(papersView)

            if (factor < 0.75 && factor > -0.75) setInViewport(true)
        }

        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [ inViewport, ref ])

    return inViewport
}

export default useInViewport
