const TEXTS_RU = {
	CODE: "RU",
	TITLE: "Авиастатс от Авиасейлс",
	SUCCESS: "Отправлено",
	MENU_TICKETS_TEXT: "Спецпредложения",
	MENU_TICKETS_LINK: "https://www.aviasales.ru/offers",
	MENU_HOTELS_TEXT: "Отели",
	MENU_HOTELS_LINK: "https://www.aviasales.ru/#hotel",
	MENU_AUTO_TEXT: "Авто",
	MENU_AUTO_LINK: "https://www.rentalcars.com/?affiliateCode=aviasalesr191&adcamp=hp",
	MENU_INSURANCE_TEXT: "Страховка",
	MENU_INSURANCE_LINK: "https://www.cherehapa.ru/?marker=CPA&partnerId=9243",
	MENU_B2B_TEXT: "Для бизнеса",
	MENU_B2B_LINK: "https://b2b.aviasales.ru",
	MENU_BLOG_TEXT: "Блог",
	MENU_BLOG_LINK: "https://www.aviasales.ru/blog",
	HERO_TITLE: "Aviastats от\u00A0Авиасейлс",
	HERO_TEXT: "Ваш помощник в&nbsp;планировании маршрутов и&nbsp;управлении доходностью",
	TRY_OUT: "Попробовать",
	OFFER_TITLE: "В\u00A0каком виде можно\u00A0получать наши\u00A0данные?",
	OFFER_TEXT: "Они доступны в&nbsp;двух вариантах: Reports и&nbsp;Dashboards",
	OFFER_REPORTS_TITLE: "Reports",
	OFFER_REPORTS_TEXT:
		"Таблицы с&nbsp;обезличенными данными. Оптимальны для использования в&nbsp;вашей собственной BI-системе",
	OFFER_DATA_TITLE: "Dashboards",
	OFFER_DATA_TEXT: "Данные в&nbsp;удобных интерактивных отчётах. Подходят для оперативного решения типовых задач",
	CUSTOMERS_TITLE: "Кому это нужно?",
	CUSTOMERS_TEXT: "Всем, кто хочет видеть рынок целиком, отслеживать тренды и&nbsp;изменения спроса",
	CUSTOMERS_AIRLINE: "Авиакомпании",
	CUSTOMERS_AIRPORT: "Аэропорты",
	CUSTOMERS_OTA: "OTA",
	PROMO_TITLE: "Почему данные Авиасейлс?",
	PROMO_TEXT:
		"Мы&nbsp;&mdash; крупнейший независимый метапоиск авиабилетов. Ежемесячно продуктами сервиса пользуются 18&nbsp;миллионов человек. Каждый пятый авиабилет в&nbsp;России куплен с&nbsp;помощью Авиасейлс.",
	BENEFITS_TITLE: "Что вы получаете?",
	BENEFITS_TEXT:
		"Aviastats&nbsp;&mdash; это самые полные данные по&nbsp;России и&nbsp;СНГ в&nbsp;режиме реального времени и&nbsp;по&nbsp;всем авиакомпаниям. А&nbsp;ещё&nbsp;&mdash; это удобно и&nbsp;просто. Теперь вы&nbsp;сможете:",
	BENEFITS_PULSE: "Обнаруживать тренды и\u00A0аномалии\u00A0спроса",
	BENEFITS_TRENDS: "Выявлять \u00ABнедообслуженные\u00BB направления",
	BENEFITS_MONITORING: "Мониторить цены и долю конкурентов",
	DEMO_TITLE: "Запросить демо",
	DEMO_TEXT: "и узнать все подробности о сервисе",
	DEMO_PLACEHOLDER: "shlyopa@aviasales.ru",
	FOOTER_TELEGRAM: "Telegram",
	FOOTER_TELEGRAM_LINK: "https://t.me/aviasales",
	FOOTER_VKONTAKTE: "ВКонтакте",
	FOOTER_VKONTAKTE_LINK: "https://vk.com/aviasales",
	FOOTER_INSTAGRAM: "Инстаграм",
	FOOTER_INSTAGRAM_LINK: "https://instagram.com/aviasales",
	FOOTER_TWITTER: "Twitter",
	FOOTER_TWITTER_LINK: "https://twitter.com/aviasales",
	FOOTER_TIKTOK: "TikTok",
	FOOTER_TIKTOK_LINK: "https://www.tiktok.com/@aviasales",
	FOOTER_DZEN: "Дзен",
	FOOTER_DZEN_LINK: "https://dzen.ru/aviasales",
	FOOTER_VIBER: "Viber",
	FOOTER_VIBER_LINK: "https://invite.viber.com/?g2=AQAtX3IQDoKIAkjNZmzOMFaqi9OHuUKPrggGpt4kfnukhlsf0gueU8hnc6dLdcqt",
	FOOTER_YOUTUBE: "YouTube",
	FOOTER_YOUTUBE_LINK: "https://www.youtube.com/aviasales",
	FOOTER_OK: "Одноклассники",
	FOOTER_OK_LINK: "https://ok.ru/aviasales",
	FOOTER_PSGR: "Журнал",
	FOOTER_PSGR_LINK:
		"https://www.aviasales.ru/psgr?utm_content=blog_psgr&utm_source=aviasales&utm_campaign=psgr&utm_medium=aviastats",
	FOOTER_WORK_LINK: "https://www.aviasales.ru/about/work",
	FOOTER_AD_LINK: "https://ad.aviasales.ru",
	FOOTER_REFERRAL_LINK: "https://www.aviasales.ru/affiliateprogram",
	FOOTER_ABOUT_LINK: "https://www.aviasales.ru/about",
	FOOTER_NEWSROOM: "Пресс-центр",
	FOOTER_NEWSROOM_LINK: "https://newsroom.aviasales.com/",
	FOOTER_SUPPORT_LINK: "https://www.aviasales.ru/mag/how-to",
	FOOTER_RULES_LINK: "https://www.aviasales.ru/terms-of-use",
	FOOTER_ABOUT: "О компании",
	FOOTER_REFERRAL: "Партнёрская программа",
	FOOTER_AD: "Реклама",
	FOOTER_WORK: "Вакансии",
	FOOTER_SUPPORT: "Помощь",
	FOOTER_RULES: "Правила",
	FOOTER_COPYRIGHT: "© 2007–2024, Aviasales — дешевые авиабилеты",
	DATA_TITLE: "Какие данные у нас есть?",
	DATA_SUBTITLE: "Всё для анализа авиационной отрасли России и СНГ:",
	DATA_PASSANGERS_TITLE: "Пассажиропоток",
	DATA_PASSANGERS_TEXT_1: "Полные данные по авиационному пассажиропотоку России",
	DATA_PASSANGERS_TEXT_2: "Доступны в реальном времени в форматах LEG и O&D",
	DATA_PASSANGERS_TEXT_3:
		"Позволяют оценивать не только пассажиропоток, но и выручку авиакомпаний на различных направлениях",
	DATA_PRICE_TITLE: "Цены конкурентов",
	DATA_PRICE_TEXT_1: "Помогают оптимизировать собственное ценообразование",
	DATA_PRICE_TEXT_2: "Можно исследовать их вручную в интерактивных отчётах или загрузить в RMS-систему",
	DATA_SEARCH_TITLE: "Поиски и клики",
	DATA_SEARCH_TEXT: "Позволяют изучать спрос на разные направления"
};

export default TEXTS_RU;
