import "./Data.sass";
import React, { FC } from "react";
import useI18n from "../../misc/i18n/useI18n";

const Data: FC = () => {
	const texts = useI18n();

	return (
		<div className='data'>
			<div className='data__inner'>
				<div className='data__content'>
					<h1 className='data__title'>{texts.DATA_TITLE}</h1>
					<p className='data__text' dangerouslySetInnerHTML={{ __html: texts.DATA_SUBTITLE }} />
					<div className='data__cards'>
						<div className='data__card data__card_passengers'>
							<div className='data__card_content'>
								<div className='data__subtitle'>{texts.DATA_PASSANGERS_TITLE}</div>
								<div className='data__body_wrapper'>
									<p className='data__body'>{texts.DATA_PASSANGERS_TEXT_1}</p>
									<p className='data__body'>{texts.DATA_PASSANGERS_TEXT_2}</p>
									<p className='data__body'>{texts.DATA_PASSANGERS_TEXT_3}</p>
								</div>
							</div>
							<div className='data__icon' />
						</div>
						<div className='data__card data__card_price'>
							<div className='data__card_content'>
								<div className='data__subtitle'>{texts.DATA_PRICE_TITLE}</div>
								<div className='data__body_wrapper'>
									<p className='data__body'>{texts.DATA_PRICE_TEXT_1}</p>
									<p className='data__body'>{texts.DATA_PRICE_TEXT_2}</p>
								</div>
							</div>
							<div className='data__icon' />
						</div>
						<div className='data__card data__card_search'>
							<div className='data__card_content'>
								<div className='data__subtitle'>{texts.DATA_SEARCH_TITLE}</div>
								<div className='data__body_wrapper'>
									<p className='data__body_single'>{texts.DATA_SEARCH_TEXT}</p>
								</div>
							</div>
							<div className='data__icon' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Data;
