import './Header.sass'
import React, { FC } from 'react'
import useI18n from '../../misc/i18n/useI18n'
import { Texts } from '../../misc/i18n/texts'

const MENU_TEMPLATE: Array<{ title: keyof Texts, href: keyof Texts}> = [
    { title: 'MENU_TICKETS_TEXT', href: 'MENU_TICKETS_LINK' },
    { title: 'MENU_HOTELS_TEXT', href: 'MENU_HOTELS_LINK' },
    { title: 'MENU_AUTO_TEXT', href: 'MENU_AUTO_LINK' },
    { title: 'MENU_INSURANCE_TEXT', href: 'MENU_INSURANCE_LINK' },
    { title: 'MENU_B2B_TEXT', href: 'MENU_B2B_LINK' },
    { title: 'MENU_BLOG_TEXT', href: 'MENU_BLOG_LINK' },
]

const Header: FC = () => {
    const texts = useI18n()
    const menu: Array<{ title: string, href: string }> = MENU_TEMPLATE.map(({ title, href }) => ({
        title: texts[title],
        href: texts[href],
    }))

    return (
        <header className='header'>
            <div className='header__inner'>
                <a
                    tabIndex={-1}
                    target='_blank'
                    className='header__logo'
                    rel='noreferrer noopener'
                    href="https://www.aviasales.ru"
                >aviasales.ru</a>
            </div>
        </header>
    )
}

export default Header
