import React, { FC } from "react";
import "./App.sass";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Offer from "./components/Offer";
import Graph from "./components/Graph";
import Customers from "./components/Customers";
import Promo from "./components/Promo";
import Benefits from "./components/Benefits";
import RequestDemo from "./components/RequestDemo";
import Footer from "./components/Footer";
import Data from "./components/Data";

const App: FC = () => {
	return (
		<div className='landing'>
			<Header />
			<Hero />
			<Data />
			<Graph />
			<Offer />
			<Customers />
			<Promo />
			<Benefits />
			<RequestDemo />
			<Footer />
		</div>
	);
};

export default App;
