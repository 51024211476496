import TEXTS_RU from './ru'
import Lang from '../Lang'
import TEXTS_EN from './en'

export type Texts = typeof TEXTS_RU

const TextsMap: { [key in Lang]: Texts } = {
    [Lang.RU]: TEXTS_RU,
    [Lang.EN]: TEXTS_EN,
}

export default TextsMap
