import { useEffect, useState } from 'react'
import { Texts } from './texts'
import I18nService from './I18nService'

const useI18n = () => {
    const [ texts, setTexts ] = useState<Texts>(I18nService.texts)

    useEffect(() => {
        const unsubscribe = I18nService.onLangChanged((newTexts) => {
            setTexts(newTexts)
        })

        return () => unsubscribe()
    }, [])

    return texts
}

export default useI18n
